if ('define' in window) {
define("discourse/theme-5/discourse/pre-initializers/theme-5-translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "theme-5-translations",
    initialize() {
      /* Translation data for theme 5 (en)*/
      const data = {
        "en": {
          "toggle_button_title": "Toggle color scheme"
        }
      };
      for (let lang in data) {
        let cursor = I18n.translations;
        for (let key of [lang, "js", "theme_translations"]) {
          cursor = cursor[key] = cursor[key] || {};
        }
        cursor[5] = data[lang];
      }
    }
  };
});
}
